import React, { Component } from "react";
import styles from "./styles.module.scss";
import images from "./images.module.scss";
import $ from "classnames";
import { setPageTitle, track, ApricotUpdate, getCreditCardsByAbbr } from "../Utility/utils";

function CardIcon(props) {
  const { value, label } = props;
  return (
    <li className={$(images["cc-" + value.toLowerCase()])}>
      <span className={$(styles.hidden)}>{label}</span>
    </li>
  );
}

class PPSSelectACH extends Component {
  constructor(props) {
    super(props);

    this.paypalRef = React.createRef();
    this.cardRef = React.createRef();

    this.state = {
      currentSelection: (this.props.defaultSelection || "echeck").toLowerCase(),
    };
  }

  render() {
    let cardMap = this.props.cardMap || [];

    if (cardMap.length) {
      if (typeof cardMap[0] === "string") {
        cardMap = getCreditCardsByAbbr(cardMap);
      }
    }

    let cardAria = "Select Credit Card Payment - Options includes ";

    for (var i = 0, ilen = cardMap.length; i < ilen; i++) {
      cardAria += cardMap[i].arilabel;
      if (i !== ilen - 1) {
        cardAria += " ";
      }
      if (i === ilen - 2 && ilen > 1) {
        cardAria += "and ";
      }
    }

    const onSelect = (e) => {
      let selection = "echeck";

      if (e.target.id === "paymentECheck") {
        selection = "echeck";
      } else if (e.target.id === "paymentCreditCard") {
        selection = "cards";
      }

      this.setState({
        currentSelection: selection,
      });
    };

    return (
      <>
        <div className={$(styles.page, "pps-select")}>
          <div className="container" role="region" aria-label="main">
            <div className="pps-container">
              <div className="row">
                <div className="col-md-6">
                  <h1 className="cb-paragraph3-res cb-font-weight-regular">Make a Payment</h1>
                  <div>
                    <p>
                      {" "}
                      The College Board accepts the following payment types: check by mail, ACH
                      (eCheck) or credit card. However, The College Board’s preferred method of
                      payment is check by mail.
                      <br />
                      <br />
                      <b>Check by mail</b> <br />
                      Please mail your check (drawn on a U.S Bank Account), with the remittance
                      portion of your invoice to:
                      <br />
                      The College Board
                      <br />
                      P.O. Box 30171
                      <br />
                      New York, N.Y. 10087-0171
                      <br />
                      <br />
                      <b>ACH Payments </b>
                      <br />
                      ACH payments <b>must</b> be drawn on a U.S. Bank Account. There is no fee
                      assessed for ACH payments. Please have your bank account information available
                      before selecting this option and confirm with your accounting team that debit
                      blocks are not enabled on your bank account.
                    </p>
                    <b>Credit Card Payments</b>
                    <br />
                    <p>
                      Please have a copy of your invoice available to facilitate payment processing.
                      We cannot accept credit card payments over $100,000.00. Please select ACH or
                      mail a check to the address below for payments that exceed $100,000.00.
                    </p>

                    <b>International Payments</b>
                    <br />
                    <p>
                      The College Board accepts international payments by check or credit card.
                      Checks should be mailed to: <br />
                      The College Board
                      <br />
                      P.O. Box 30171
                      <br />
                      New York, N.Y. 10087-0171
                      <br />
                    </p>
                    <p>
                      <b>W9</b>– for a copy of our W9 form, please click{" "}
                      <a href="W9.pdf" target="_blank">
                        here.
                      </a>
                      <br />
                      If you have questions, please contact customer service following the
                      instructions on your invoice.
                    </p>
                  </div>

                  {/* Payment Radio Group */}
                  <div className="cb-card cb-border cb-no-box-shadow cb-margin-bottom-48">
                    <div className="cb-radio">
                      <label
                        className="position-relative"
                        htmlFor="paymentECheck"
                        aria-label="Select Payment by by ACH - E Check"
                      >
                        <input
                          onClick={onSelect}
                          ref={this.paypalRef}
                          type="radio"
                          name="paymentOptions"
                          id="paymentECheck"
                          value="echeck"
                          defaultChecked={this.state.currentSelection === "echeck"}
                        />
                        <span className={$(images["logo-echeck"])}></span>
                      </label>
                      <span className={$(styles["ach-help-text"])}>
                        ACH Processing. Preferred Payment Method.
                      </span>
                    </div>

                    <div className="cb-radio cb-margin-top-48">
                      <label
                        className="position-relative"
                        htmlFor="paymentCreditCard"
                        aria-label={cardAria}
                      >
                        <input
                          onClick={onSelect}
                          ref={this.cardRef}
                          type="radio"
                          name="paymentOptions"
                          id="paymentCreditCard"
                          value="creditCard"
                          defaultChecked={this.state.currentSelection === "cards"}
                        />
                        <ul
                          aria-hidden="true"
                          role="presentation"
                          className={$(styles["cc-container"])}
                        >
                          {cardMap.map((c) => (
                            <CardIcon key={c.label} value={c.value} label={c.label} />
                          ))}
                          {cardMap.find((c) => c.value === "DI") && (
                            <CardIcon key="unionpay" value="di2" label="UnionPay" />
                          )}
                        </ul>
                      </label>
                    </div>
                  </div>

                  <div className="cb-btn-row">
                    <button
                      name="submit"
                      onClick={() => {
                        this.props.onSubmit(this.state.currentSelection);
                      }}
                      className="cb-btn cb-btn-primary"
                    >
                      Submit
                    </button>
                    <button
                      name="cancel"
                      onClick={this.props.onCancel}
                      className="cb-btn cb-btn-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidUpdate() {
    ApricotUpdate();
  }

  componentDidMount() {
    ApricotUpdate();

    setPageTitle("Select A Payment Method | Make a Payment");
    track("payment-option", this.props.appId, this.props.flowCode);
  }
}

export default PPSSelectACH;
