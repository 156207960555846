import React, {Component} from 'react';
import styles from './styles.module.scss';
import { 
  setPageTitle, 
  track, 
  ApricotUpdate } 
from '../Utility/utils';
import { ApricotErrorView } from '../Pages/elements';
import $ from 'classnames';

class PPSError extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { errorInfo } = this.props;
    const { errorTitle, errorMessage, errors } = errorInfo;

    return (
      <div className={$(styles.page, 'pps-error')}>
        <div className="container" role="region" aria-label="main">
          <div className="pps-container">
            <div className="row">
              <div className="col-md-6">
                { errorInfo !== undefined && <ApricotErrorView 
                  errorTitle={errorTitle}
                  errorMessage={errorMessage}
                  errors={errors}
                >{this.props.children}</ApricotErrorView>}
              </div>
            </div>            
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    ApricotUpdate();

    setPageTitle('An Error Has Occurred');
    track('app-error', this.props.appId, this.props.flowCode);
  }
}

export default PPSError