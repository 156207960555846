import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";


window.appConfig = createAppConfig();

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

function createAppConfig() {
  console.log("print all env", process.env);
  const envName = process.env.REACT_APP_build_env;
  if (!envName) throw new Error("ENV_NAME is not set");

  const REACT_APP_vantivUrl = process.env.REACT_APP_vantivUrl;
  if (!REACT_APP_vantivUrl) throw new Error("REACT_APP_vantivUrl is not set");

  const REACT_APP_paypalUrl = process.env.REACT_APP_paypalUrl;
  if (!REACT_APP_paypalUrl) throw new Error("REACT_APP_paypalUrl is not set");

  const REACT_APP_wpcUrl = process.env.REACT_APP_wpcUrl;
  if (!REACT_APP_wpcUrl) throw new Error("REACT_APP_wpcUrl is not set");

  return {
    envName,
    REACT_APP_vantivUrl,
    REACT_APP_paypalUrl,
    REACT_APP_wpcUrl,
  };
}
