import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function SessionTimer(props) {
  const [timeLeft, setTimeLeft] = useState(10 * 60); // initialize the time left to 10 hours minus 5 minutes
  const location = useLocation();
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000); // decrease the time left by one second every 1000 milliseconds (one second)

    return () => clearInterval(interval);
  }, [timeLeft]);

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hoursStr = hours.toString().padStart(2, "0");
    const minutesStr = minutes.toString().padStart(2, "0");
    const secondsStr = remainingSeconds.toString().padStart(2, "0");

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }

  useEffect(() => {
    setTimeLeft(10 * 60); // reset the timer to 5 minutes on route change
  }, [location]);

  function resetTimer() {
    setTimeLeft(10 * 60); // reset the timer to 10 minutes
  }

  /*
  useEffect(() => {
    if (resetTimer !== undefined) {
      resetTimer();
    }
  }, [props.resetTimer]);*/

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          {timeLeft <= 2 * 60 ? (
            <div className="cb-notification" role="region">
              <div className="cb-notification-container">
                <div className="cb-notification-header">
                  <span
                    className="cb-icon cb-icon-circular cb-megaphone cb-red1-color"
                    aria-hidden="true"
                  ></span>
                  <p>
                    Please be aware that your session will end soon. You have {formatTime(timeLeft)}{" "}
                    remaining. Please save your work.
                  </p>
                </div>
              </div>
            </div>
          ) : timeLeft <= 4 * 60 ? (
            <div className="cb-notification" role="region">
              <div className="cb-notification-container">
                <div className="cb-notification-header">
                  <span
                    className="cb-icon cb-icon-circular cb-megaphone cb-red1-color"
                    aria-hidden="true"
                  ></span>
                  <p>
                    Please be aware that your session will end soon. You have {formatTime(timeLeft)}{" "}
                    remaining. Please save your work.
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
