import React, { Component } from "react";
import Routes from "./Routes";

class App extends Component {
  render() {
    const apricot = window.cb.apricot;
    if (apricot) {
      apricot.CBInputMethod();
    }
    return (
      <div className="App cb-margin-top-24" id="main_content">
        <Routes />
      </div>
    );
  }
}

export default App;
