export const Months = [
  {
    label: "January",
    value: "01",
  },
  {
    label: "February",
    value: "02",
  },
  {
    label: "March",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "June",
    value: "06",
  },
  {
    label: "July",
    value: "07",
  },
  {
    label: "August",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];

export const CreditCards = [
  {
    label: "Discover",
    arilabel: "Discover",
    value: "DI",
    sub: [
      {
        label: "Diners Club",
        value: "DC",
      },
    ],
    range: 19,
    IIN: ["3", "6"],
  },
  {
    label: "Visa",
    arilabel: "Visa",
    value: "VI",
    range: 19,
    IIN: ["4"],
  },
  {
    label: "Mastercard",
    arilabel: "Mastercard",
    value: "MC",
    range: 16,
    IIN: ["2", "5"],
  },
  {
    label: "American Express",
    arilabel: "American Express",
    value: "AX",
    range: 15,
    IIN: ["3"],
  },
  {
    label: "JCB",
    arilabel: "JCB and UnionPay",
    value: "JC",
    range: 19,
    IIN: ["3"],
  },
];

export function getCreditCardsByAbbr(creditCards) {
  let cards = [];

  creditCards.forEach((c) => {
    CreditCards.forEach((cc) => {
      if (c === cc.value) {
        cards.push(cc);
      }
    });
  });

  return cards;
}

export function getCreditCardData(abbr) {
  let cards = getCreditCardsByAbbr([abbr]);
  return cards.length ? cards[0] : false;
}

export function getYears(years) {
  const date = new Date();
  const year = parseInt(date.getFullYear());

  let availableYears = [];

  for (var i = year, ilen = year + years; i < ilen; i++) {
    var value = "" + i;

    availableYears.push({
      label: value,
      value: value.slice(2),
    });
  }

  return availableYears;
}

export function setPageTitle(pageTitle) {
  document.title = pageTitle + " - College Board";
}

export function setPageDescription(description) {
  let node = document.createElement("meta");
  node.setAttribute("name", "description");
  node.setAttribute("content", description);

  document.head.appendChild(node);
}

export function track(pageCode, appId, flowCode) {
  let trackingObject = {
    page: {
      pageCode: pageCode,
      flowCode: flowCode,
    },
    appId: parseInt(appId),
  };

  try {
    window.kiwi.mergeDdl(trackingObject);
  } catch (e) {
    if (window._satellite) {
      // after 2/17 _satellite.notify will still work but is deprecated. It will be _satellite.logger.log (or error/warn/info)
      window._satellite.notify(
        "Something went wrong with kiwi.mergeDdl(): " + e.message
      );
    } else {
      console.log("Failure: " + e.message);
    }
  }

  if (window._satellite) {
    window._satellite.track("cbTrack.viewInDom");
  }
}

export function buildPopover(trigger) {
  const apricot = window.cb.apricot;

  apricot.CBTooltip({
    elem: document.querySelector(`#${trigger}`),
    placement: "top",
    style: "cb-tooltip-light",
  });
}

// TBD: This needs to be updated to use the XPM API
export const CountryMap = [
  { country: "Afghanistan", abbr: "AF" },
  { country: "Aland Islands", abbr: "AX" },
  { country: "Albania", abbr: "AL" },
  { country: "Algeria", abbr: "DZ" },
  { country: "Andorra", abbr: "AD" },
  { country: "Angola", abbr: "AO" },
  { country: "Anguilla", abbr: "AI" },
  { country: "Antarctica", abbr: "AQ" },
  { country: "Antigua and Barbuda", abbr: "AG" },
  { country: "Argentina", abbr: "AR" },
  { country: "Armenia", abbr: "AM" },
  { country: "Aruba", abbr: "AW" },
  { country: "Australia", abbr: "AU" },
  { country: "Austria", abbr: "AT" },
  { country: "Azerbaijan", abbr: "AZ" },
  { country: "Bahamas", abbr: "BS" },
  { country: "Bahrain", abbr: "BH" },
  { country: "Bangladesh", abbr: "BD" },
  { country: "Barbados", abbr: "BB" },
  { country: "Belarus", abbr: "BY" },
  { country: "Belgium", abbr: "BE" },
  { country: "Belize", abbr: "BZ" },
  { country: "Benin", abbr: "BJ" },
  { country: "Bermuda", abbr: "BM" },
  { country: "Bhutan", abbr: "BT" },
  { country: "Bolivia", abbr: "BO" },
  { country: "Bosnia and Herzegovina", abbr: "BA" },
  { country: "Botswana", abbr: "BW" },
  { country: "Bouvet Island", abbr: "BV" },
  { country: "Brazil", abbr: "BR" },
  { country: "British Indian Ocean Territory", abbr: "IO" },
  { country: "Brunei Darussalam", abbr: "BN" },
  { country: "Bulgaria", abbr: "BG" },
  { country: "Burkina Faso", abbr: "BF" },
  { country: "Burundi", abbr: "BI" },
  { country: "Cambodia", abbr: "KH" },
  { country: "Cameroon", abbr: "CM" },
  {
    country: "Canada",
    abbr: "CA",
    requireZipCode: true,
    states: [
      { state: "ALBERTA", abbr: "AB" },
      { state: "BRITISH COLUMBIA", abbr: "BC" },
      { state: "MANITOBA", abbr: "MB" },
      { state: "NEW BRUNSWICK", abbr: "NB" },
      { state: "NEWFOUNDLAND AND LABRADOR", abbr: "NL" },
      { state: "NORTHWEST TERRITORIES", abbr: "NT" },
      { state: "NOVA SCOTIA", abbr: "NS" },
      { state: "NUNAVUT", abbr: "NU" },
      { state: "ONTARIO", abbr: "ON" },
      { state: "PRINCE EDWARD ISLAND", abbr: "PE" },
      { state: "QUEBEC", abbr: "QC" },
      { state: "SASKATCHEWAN", abbr: "SK" },
      { state: "YUKON", abbr: "YT" },
    ],
  },
  { country: "Cape Verde", abbr: "CV" },
  { country: "Cayman Islands", abbr: "KY" },
  { country: "Central African Republic", abbr: "CF" },
  { country: "Chad", abbr: "TD" },
  { country: "Chile", abbr: "CL" },
  { country: "China", abbr: "CN" },
  { country: "Christmas Island", abbr: "CX" },
  { country: "Cocos (Keeling) Islands", abbr: "CC" },
  { country: "Colombia", abbr: "CO" },
  { country: "Comoros", abbr: "KM" },
  { country: "Congo", abbr: "CG" },
  { country: "Congo, The Democratic Republic Of The", abbr: "CD" },
  { country: "Cook Islands", abbr: "CK" },
  { country: "Costa Rica", abbr: "CR" },
  { country: "Cote D'Ivoire", abbr: "CI" },
  { country: "Croatia", abbr: "HR" },
  { country: "Cuba", abbr: "CU" },
  { country: "Cyprus", abbr: "CY" },
  { country: "Czech Republic", abbr: "CZ" },
  { country: "Denmark", abbr: "DK" },
  { country: "Djibouti", abbr: "DJ" },
  { country: "Dominica", abbr: "DM" },
  { country: "Dominican Republic", abbr: "DO" },
  { country: "Ecuador", abbr: "EC" },
  { country: "Egypt", abbr: "EG" },
  { country: "El Salvador", abbr: "SV" },
  { country: "Equatorial Guinea", abbr: "GQ" },
  { country: "Eritrea", abbr: "ER" },
  { country: "Estonia", abbr: "EE" },
  { country: "Ethiopia", abbr: "ET" },
  { country: "Falkland Islands (Malvinas)", abbr: "FK" },
  { country: "Faroe Islands", abbr: "FO" },
  { country: "Fiji", abbr: "FJ" },
  { country: "Finland", abbr: "FI" },
  { country: "France", abbr: "FR" },
  { country: "French Guiana", abbr: "GF" },
  { country: "French Polynesia", abbr: "PF" },
  { country: "French Southern Territories", abbr: "TF" },
  { country: "Gabon", abbr: "GA" },
  { country: "Gambia", abbr: "GM" },
  { country: "Georgia", abbr: "GE" },
  { country: "Germany", abbr: "DE" },
  { country: "Ghana", abbr: "GH" },
  { country: "Gibraltar", abbr: "GI" },
  { country: "Greece", abbr: "GR" },
  { country: "Greenland", abbr: "GL" },
  { country: "Grenada", abbr: "GD" },
  { country: "Guadeloupe", abbr: "GP" },
  { country: "Guatemala", abbr: "GT" },
  { country: "Guinea", abbr: "GN" },
  { country: "Guinea-Bissau", abbr: "GW" },
  { country: "Guyana", abbr: "GY" },
  { country: "Haiti", abbr: "HT" },
  { country: "Heard Island and Mcdonald Islands", abbr: "HM" },
  { country: "Holy See (Vatican City State)", abbr: "VA" },
  { country: "Honduras", abbr: "HN" },
  { country: "Hong Kong", abbr: "HK" },
  { country: "Hungary", abbr: "HU" },
  { country: "Iceland", abbr: "IS" },
  { country: "India", abbr: "IN" },
  { country: "Indonesia", abbr: "ID" },
  { country: "Iran, Islamic Republic Of", abbr: "IR" },
  { country: "Iraq", abbr: "IQ" },
  { country: "Ireland", abbr: "IE" },
  { country: "Israel", abbr: "IL" },
  { country: "Italy", abbr: "IT" },
  { country: "Jamaica", abbr: "JM" },
  { country: "Japan", abbr: "JP" },
  { country: "Jordan", abbr: "JO" },
  { country: "Kazakhstan", abbr: "KZ" },
  { country: "Kenya", abbr: "KE" },
  { country: "Kiribati", abbr: "KI" },
  { country: "Korea, Democratic People's Republic Of", abbr: "KP" },
  { country: "Korea, Republic Of", abbr: "KR" },
  { country: "Kosovo", abbr: "XK" },
  { country: "Kuwait", abbr: "KW" },
  { country: "Kyrgyzstan", abbr: "KG" },
  { country: "Lao People's Democratic Republic", abbr: "LA" },
  { country: "Latvia", abbr: "LV" },
  { country: "Lebanon", abbr: "LB" },
  { country: "Lesotho", abbr: "LS" },
  { country: "Liberia", abbr: "LR" },
  { country: "Libyan Arab Jamahiriya", abbr: "LY" },
  { country: "Liechtenstein", abbr: "LI" },
  { country: "Lithuania", abbr: "LT" },
  { country: "Luxembourg", abbr: "LU" },
  { country: "Macao", abbr: "MO" },
  { country: "Macedonia, The Former Yugoslav Republic Of", abbr: "MK" },
  { country: "Madagascar", abbr: "MG" },
  { country: "Malawi", abbr: "MW" },
  { country: "Malaysia", abbr: "MY" },
  { country: "Maldives", abbr: "MV" },
  { country: "Mali", abbr: "ML" },
  { country: "Malta", abbr: "MT" },
  { country: "Martinique", abbr: "MQ" },
  { country: "Mauritania", abbr: "MR" },
  { country: "Mauritius", abbr: "MU" },
  { country: "Mayotte", abbr: "YT" },
  { country: "Mexico", abbr: "MX" },
  { country: "Moldova, Republic Of", abbr: "MD" },
  { country: "Monaco", abbr: "MC" },
  { country: "Mongolia", abbr: "MN" },
  { country: "Montserrat", abbr: "MS" },
  { country: "Morocco", abbr: "MA" },
  { country: "Mozambique", abbr: "MZ" },
  { country: "Myanmar", abbr: "MM" },
  { country: "Namibia", abbr: "NA" },
  { country: "Nauru", abbr: "NR" },
  { country: "Nepal", abbr: "NP" },
  { country: "Netherlands", abbr: "NL" },
  { country: "Netherlands Antilles", abbr: "AN" },
  { country: "New Caledonia", abbr: "NC" },
  { country: "New Zealand", abbr: "NZ" },
  { country: "Nicaragua", abbr: "NI" },
  { country: "Niger", abbr: "NE" },
  { country: "Nigeria", abbr: "NG" },
  { country: "Niue", abbr: "NU" },
  { country: "Norfolk Island", abbr: "NF" },
  { country: "Norway", abbr: "NO" },
  { country: "Oman", abbr: "OM" },
  { country: "Pakistan", abbr: "PK" },
  { country: "Palestine, State of", abbr: "PS" },
  { country: "Panama", abbr: "PA" },
  { country: "Papua New Guinea", abbr: "PG" },
  { country: "Paraguay", abbr: "PY" },
  { country: "Peru", abbr: "PE" },
  { country: "Philippines", abbr: "PH" },
  { country: "Pitcairn", abbr: "PN" },
  { country: "Poland", abbr: "PL" },
  { country: "Portugal", abbr: "PT" },
  { country: "Qatar", abbr: "QA" },
  { country: "Reunion", abbr: "RE" },
  { country: "Romania", abbr: "RO" },
  { country: "Russian Federation", abbr: "RU" },
  { country: "Rwanda", abbr: "RW" },
  { country: "Saint Helena", abbr: "SH" },
  { country: "Saint Kitts and Nevis", abbr: "KN" },
  { country: "Saint Lucia", abbr: "LC" },
  { country: "Saint Pierre and Miquelon", abbr: "PM" },
  { country: "Saint Vincent and The Grenadines", abbr: "VC" },
  { country: "Samoa", abbr: "WS" },
  { country: "San Marino", abbr: "SM" },
  { country: "Sao Tome and Principe", abbr: "ST" },
  { country: "Saudi Arabia", abbr: "SA" },
  { country: "Senegal", abbr: "SN" },
  { country: "Serbia And Montenegro", abbr: "CS" },
  { country: "Seychelles", abbr: "SC" },
  { country: "Sierra Leone", abbr: "SL" },
  { country: "Singapore", abbr: "SG" },
  { country: "Slovakia", abbr: "SK" },
  { country: "Slovenia", abbr: "SI" },
  { country: "Solomon Islands", abbr: "SB" },
  { country: "Somalia", abbr: "SO" },
  { country: "South Africa", abbr: "ZA" },
  { country: "South Georgia and The South Sandwich Islands", abbr: "GS" },
  { country: "Spain", abbr: "ES" },
  { country: "Sri Lanka", abbr: "LK" },
  { country: "Sudan", abbr: "SD" },
  { country: "Suriname", abbr: "SR" },
  { country: "Svalbard and Jan Mayen", abbr: "SJ" },
  { country: "Swaziland", abbr: "SZ" },
  { country: "Sweden", abbr: "SE" },
  { country: "Switzerland", abbr: "CH" },
  { country: "Syrian Arab Republic", abbr: "SY" },
  { country: "Taiwan", abbr: "TW" },
  { country: "Tajikistan", abbr: "TJ" },
  { country: "Tanzania, United Republic Of", abbr: "TZ" },
  { country: "Thailand", abbr: "TH" },
  { country: "Timor-Leste", abbr: "TL" },
  { country: "Togo", abbr: "TG" },
  { country: "Tokelau", abbr: "TK" },
  { country: "Tonga", abbr: "TO" },
  { country: "Trinidad and Tobago", abbr: "TT" },
  { country: "Tunisia", abbr: "TN" },
  { country: "Turkey", abbr: "TR" },
  { country: "Turkmenistan", abbr: "TM" },
  { country: "Turks and Caicos Islands", abbr: "TC" },
  { country: "Tuvalu", abbr: "TV" },
  { country: "Uganda", abbr: "UG" },
  { country: "Ukraine", abbr: "UA" },
  { country: "United Arab Emirates", abbr: "AE" },
  { country: "United Kingdom", abbr: "GB" },
  {
    country: "United States",
    abbr: "US",
    requireZipCode: true,
    states: [
      { state: "ALABAMA", abbr: "AL" },
      { state: "ALASKA", abbr: "AK" },
      { state: "AMERICAN SAMOA", abbr: "AS" },
      { state: "ARIZONA", abbr: "AZ" },
      { state: "ARKANSAS", abbr: "AR" },
      { state: "ARMED FORCES AMERICAS", abbr: "AA" },
      { state: "ARMED FORCES EUROPE", abbr: "AE" },
      { state: "ARMED FORCES PACIFIC", abbr: "AP" },
      { state: "CALIFORNIA", abbr: "CA" },
      { state: "COLORADO", abbr: "CO" },
      { state: "CONNECTICUT", abbr: "CT" },
      { state: "DELAWARE", abbr: "DE" },
      { state: "DISTRICT OF COLUMBIA", abbr: "DC" },
      { state: "FLORIDA", abbr: "FL" },
      { state: "GEORGIA", abbr: "GA" },
      { state: "GUAM", abbr: "GU" },
      { state: "HAWAII", abbr: "HI" },
      { state: "IDAHO", abbr: "ID" },
      { state: "ILLINOIS", abbr: "IL" },
      { state: "INDIANA", abbr: "IN" },
      { state: "IOWA", abbr: "IA" },
      { state: "KANSAS", abbr: "KS" },
      { state: "KENTUCKY", abbr: "KY" },
      { state: "LOUISIANA", abbr: "LA" },
      { state: "MAINE", abbr: "ME" },
      { state: "MARSHALL ISLANDS", abbr: "MH" },
      { state: "MARYLAND", abbr: "MD" },
      { state: "MASSACHUSETTS", abbr: "MA" },
      { state: "MICHIGAN", abbr: "MI" },
      { state: "MICRONESIA", abbr: "FM" },
      { state: "MINNESOTA", abbr: "MN" },
      { state: "MISSISSIPPI", abbr: "MS" },
      { state: "MISSOURI", abbr: "MO" },
      { state: "MONTANA", abbr: "MT" },
      { state: "NEBRASKA", abbr: "NE" },
      { state: "NEVADA", abbr: "NV" },
      { state: "NEW HAMPSHIRE", abbr: "NH" },
      { state: "NEW JERSEY", abbr: "NJ" },
      { state: "NEW MEXICO", abbr: "NM" },
      { state: "NEW YORK", abbr: "NY" },
      { state: "NORTH CAROLINA", abbr: "NC" },
      { state: "NORTH DAKOTA", abbr: "ND" },
      { state: "NORTHERN MARIANA ISLANDS", abbr: "MP" },
      { state: "OHIO", abbr: "OH" },
      { state: "OKLAHOMA", abbr: "OK" },
      { state: "OREGON", abbr: "OR" },
      { state: "PALAU", abbr: "PW" },
      { state: "PENNSYLVANIA", abbr: "PA" },
      { state: "PUERTO RICO", abbr: "PR" },
      { state: "RHODE ISLAND", abbr: "RI" },
      { state: "SOUTH CAROLINA", abbr: "SC" },
      { state: "SOUTH DAKOTA", abbr: "SD" },
      { state: "TENNESSEE", abbr: "TN" },
      { state: "TEXAS", abbr: "TX" },
      { state: "U.S. MINOR OUTLYING ISLANDS", abbr: "UM" },
      { state: "UTAH", abbr: "UT" },
      { state: "VERMONT", abbr: "VT" },
      { state: "VIRGIN ISLANDS, U.S.", abbr: "VI" },
      { state: "VIRGINIA", abbr: "VA" },
      { state: "WASHINGTON", abbr: "WA" },
      { state: "WEST VIRGINIA", abbr: "WV" },
      { state: "WISCONSIN", abbr: "WI" },
      { state: "WYOMING", abbr: "WY" },
    ],
  },
  { country: "Uruguay", abbr: "UY" },
  { country: "Uzbekistan", abbr: "UZ" },
  { country: "Vanuatu", abbr: "VU" },
  { country: "Venezuela", abbr: "VE" },
  { country: "Viet Nam", abbr: "VN" },
  { country: "Virgin Islands, British", abbr: "VG" },
  { country: "Wallis and Futuna", abbr: "WF" },
  { country: "Western Sahara", abbr: "EH" },
  { country: "Yemen", abbr: "YE" },
  { country: "Zambia", abbr: "ZM" },
  { country: "Zimbabwe", abbr: "ZW" },
];

export const ApricotUpdate = () => {
  const apricot = window.cb.apricot;

  // Clear Input Feature
  document.querySelectorAll(".cb-clear-input").forEach((elem) => {
    apricot.CBForm.clearInput({
      elem: elem.querySelector("input"),
    });
  });

  document.querySelectorAll(".cb-select").forEach((elem) => {
    apricot.CBForm.customSelectElement({
      elem: elem.querySelector("select"),
    });
  });

  document.querySelectorAll(".cb-radio").forEach((elem) => {
    apricot.CBForm.customFormElement({
      elem: elem.querySelector("input"),
      type: "radio",
    });
  });
};
