import React, { Component } from "react";
import styles from "./styles.module.scss";
import images from "./images.module.scss";
import $ from "classnames";
import {
  setPageTitle,
  track,
  ApricotUpdate,
  getCreditCardsByAbbr,
} from "../Utility/utils";

function CardIcon(props) {
  const { value, label } = props;
  return (
    <li className={$(images["cc-" + value.toLowerCase()])}>
      <span className={$(styles.hidden)}>{label}</span>
    </li>
  );
}

class PPSSelect extends Component {
  constructor(props) {
    super(props);

    this.paypalRef = React.createRef();
    this.cardRef = React.createRef();

    this.state = {
      currentSelection: (this.props.defaultSelection || "cards").toLowerCase(),
    };
  }

  render() {
    let cardMap = this.props.cardMap || [];

    if (cardMap.length) {
      if (typeof cardMap[0] === "string") {
        cardMap = getCreditCardsByAbbr(cardMap);
      }
    }

    let cardAria = "Select Credit Card Payment - Options includes ";

    for (var i = 0, ilen = cardMap.length; i < ilen; i++) {
      cardAria += cardMap[i].arilabel;
      if (i !== ilen - 1) {
        cardAria += " ";
      }
      if (i === ilen - 2 && ilen > 1) {
        cardAria += "and ";
      }
    }

    const onSelect = (e) => {
      let selection = "cards";

      if (e.target.id === "paymentPayPal") {
        selection = "paypal";
      } else if (e.target.id === "paymentCreditCard") {
        selection = "cards";
      }

      this.setState({
        currentSelection: selection,
      });
    };

    return (
      <>
        <div className={$(styles.page, "pps-select")}>
          <div className="container" role="region" aria-label="main">
            <div className="pps-container">
              <div className="row">
                <div className="col-md-6">
                  <h1 className="cb-paragraph3-res cb-font-weight-regular">
                    Make a Payment
                  </h1>
                  <div className="cb-border-top cb-padding-bottom-24" />
                  <h2 className="cb-paragraph2-res cb-font-weight-light cb-margin-bottom-24">
                    Payment Method
                  </h2>
                  <div className="cb-card cb-border cb-no-box-shadow cb-margin-bottom-48">
                    <div className="cb-radio">
                      <label
                        className="position-relative"
                        htmlFor="paymentPayPal"
                        aria-label="Select Payment by PayPal"
                      >
                        <input
                          onClick={onSelect}
                          ref={this.paypalRef}
                          type="radio"
                          name="paymentOptions"
                          id="paymentPayPal"
                          value="payPal"
                          defaultChecked={
                            this.state.currentSelection === "paypal"
                          }
                        />
                        <span className={$(images["logo-paypal"])}></span>
                      </label>
                      <span className={$(styles["help-text"])}>
                        (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.paypal.com/en/webapps/mpp/paypal-popup"
                          title="[Opens in a new Window]"
                        >
                          What is PayPal?
                        </a>
                        )
                      </span>
                    </div>

                    <div className="cb-radio cb-margin-top-48">
                      <label
                        className="position-relative"
                        htmlFor="paymentCreditCard"
                        aria-label={cardAria}
                      >
                        <input
                          onClick={onSelect}
                          ref={this.cardRef}
                          type="radio"
                          name="paymentOptions"
                          id="paymentCreditCard"
                          value="creditCard"
                          defaultChecked={
                            this.state.currentSelection === "cards"
                          }
                        />
                        <ul
                          aria-hidden="true"
                          role="presentation"
                          className={$(styles["cc-container"])}
                        >
                          {cardMap.map((c) => (
                            <CardIcon
                              key={c.label}
                              value={c.value}
                              label={c.label}
                            />
                          ))}
                          {cardMap.find((c) => c.value === "DI") && (
                            <CardIcon
                              key="unionpay"
                              value="di2"
                              label="UnionPay"
                            />
                          )}
                        </ul>
                      </label>
                    </div>
                  </div>

                  <div className="cb-btn-row">
                    <button
                      name="submit"
                      onClick={() => {
                        this.props.onSubmit(this.state.currentSelection);
                      }}
                      className="cb-btn cb-btn-primary"
                    >
                      Submit
                    </button>
                    <button
                      name="cancel"
                      onClick={this.props.onCancel}
                      className="cb-btn cb-btn-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidUpdate() {
    ApricotUpdate();
  }

  componentDidMount() {
    ApricotUpdate();

    setPageTitle("Select A Payment Method | Make a Payment");
    track("payment-option", this.props.appId, this.props.flowCode);
  }
}

export default PPSSelect;
