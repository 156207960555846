import React, { Component } from "react";
import styles from "./styles.module.scss";
import images from "./images.module.scss";
import {
  setPageTitle,
  track,
  CreditCards,
  Months,
  getYears,
  buildPopover,
  CountryMap,
  ApricotUpdate,
} from "../Utility/utils";
import $ from "classnames";
import { isEqual, clone, cloneDeep, merge, findIndex } from "lodash";
import {
  ApricotFormGroupInput,
  ApricotFormGroupSelect,
  ApricotErrorView,
} from "./elements";

let submitValidationCount = 0;
let currentSubmitionCount = 0;

/**
 * Checks the state of validation for the form being looked at. The object being analyzed should be
 * based on whether the state of the item is valid. True being valid, false being invalid. If every
 * key in the object is valid, the validation is complete. Otherwise, it will be false.
 *
 * @param {object} state The state in a key value pair. The "key" should be the id, the "value" should
 * be whether it is valid or invalid. True is valid. False is invalid.
 */
const validateCompletion = (state, excludeList) => {
  let isComplete = true;
  let currentState = cloneDeep(state);

  if (excludeList) {
    for (let i = 0, ilen = excludeList.length; i < ilen; i++) {
      delete currentState[excludeList[i]];
    }
  }

  let invalid = [];

  for (let key in currentState) {
    if (
      !currentState[key] ||
      currentState[key] === "none" ||
      currentState[key] === null
    ) {
      isComplete = false;
      invalid.push(key);
    }
  }

  return isComplete;
};

const isLoading = (state) => {
  let isLoading = false;
  for (let key in state) {
    if (state[key]) {
      isLoading = true;
      break;
    }
  }

  return isLoading;
};

const formatStatus = (id, isComplete, state, values) => {
  return {
    id: id,
    isComplete: isComplete,
    state: state,
    values: values,
  };
};

const copyObjectByList = (object, ...list) => {
  let obj = {};
  let items = [];

  if (!object) {
    object = {};
  }

  if (typeof list[0] === "string") {
    items = list;
  } else {
    items = list[0];
  }

  items.forEach((v) => {
    if (object[v]) {
      obj[v] = object[v];
    }
  });

  return obj;
};

const getError = (errorInfo, id) => {
  if (!errorInfo) {
    return false;
  }

  if (errorInfo.errors) {
    for (let i = 0, ilen = errorInfo.errors.length; i < ilen; i++) {
      let e = errorInfo.errors[i];

      if (e.id === id) {
        return e.error;
      }
    }
  }

  return false;
};

const buildFormState = (formInputs, formValues, errorInfo) => {
  let state = {};
  formInputs.forEach((i) => {
    let formValue = !!formValues[i];
    let error = getError(errorInfo, i);

    state[i] = formValue && error === false;
  });

  return state;
};

const buildFormErrorState = (formInputs, formErrors) => {
  let state = {};
  formInputs.forEach((i) => {
    state[i] = getError(formErrors, i);
  });

  return state;
};

class UserDetailsName extends Component {
  constructor(props) {
    super(props);

    this.formInputs = ["firstName", "lastName"];
    this.values = copyObjectByList(this.props.defaults, this.formInputs);
    this.defaultErrors = buildFormErrorState(
      this.formInputs,
      this.props.errorInfo
    );
    this.state = buildFormState(
      this.formInputs,
      this.values,
      this.props.errorInfo
    );
    this.status = formatStatus(
      "UserDetailsName",
      validateCompletion(this.state),
      this.state,
      this.values
    );
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.errorInfo, prevProps.errorInfo)) {
      let updatedState = buildFormState(
        this.formInputs,
        this.values,
        this.props.errorInfo
      );

      this.setState(updatedState);

      this.status = formatStatus(
        this.status.id,
        validateCompletion(updatedState),
        updatedState,
        this.values
      );

      const { onLoad } = this.props;
      if (onLoad) {
        onLoad(this.status);
      }
    }
  }

  render() {
    const { onChange, defaults, errorInfo } = this.props;
    let defaultErrors = buildFormErrorState(this.formInputs, errorInfo);

    const onChangeHandler = (e) => {
      let currentState = clone(this.state);
      currentState[e.id] = !e.hasErrors;

      this.setState(merge(this.state, currentState));

      this.values[e.id] = e.value;

      this.status = formatStatus(
        this.status.id,
        validateCompletion(currentState),
        currentState,
        this.values
      );

      if (onChange) {
        onChange(this.status);
      }
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <p className={$(styles.instructions)}>
            <sup>*</sup>= Required
          </p>
          <ApricotFormGroupInput
            id="firstName"
            label="First Name"
            isRequired={true}
            maxLength="15"
            errorInfo={errorInfo}
            errorMessage={defaultErrors.firstName}
            defaultValue={defaults.firstName}
            onChange={onChangeHandler}
          />
        </div>
        <div className="col-md-12">
          <ApricotFormGroupInput
            id="lastName"
            label="Last Name"
            isRequired={true}
            maxLength="15"
            errorInfo={errorInfo}
            errorMessage={defaultErrors.lastName}
            defaultValue={defaults.lastName}
            onChange={onChangeHandler}
          />
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { onLoad } = this.props;
    if (onLoad) {
      onLoad(this.status);
    }
  }
}

class UserDetailsPostalAddress extends Component {
  constructor(props) {
    super(props);

    this.formInputs = [
      "address1",
      "address2",
      "state",
      "city",
      "country",
      "zipCode",
      "email",
    ];
    this.values = copyObjectByList(this.props.defaults, this.formInputs);
    this.values.country = !!this.values.country ? this.values.country : "US";
    this.values.state = !!this.values.state ? this.values.state : null;

    this.state = merge(
      {
        defaultCountry: this.values.country,
        defaultState: this.values.state,
      },
      buildFormState(this.formInputs, this.values)
    );

    this.values.country = this.state.defaultCountry;

    let excludeList = ["defaultCountry", "defaultState", "address2"];
    let states =
      CountryMap[
        findIndex(CountryMap, (c) => {
          return c.abbr === this.values.country;
        })
      ].states;
    if (!states) {
      excludeList.push("state");
      excludeList.push("zipCode");
      delete this.values["state"];
    }

    this.defaultErrors = buildFormErrorState(
      this.formInputs,
      this.props.errorInfo
    );

    if (!this.props.emailRequired) {
      excludeList.push("email");
      console.log("excludeList2", excludeList);
    }

    this.status = formatStatus(
      "UserDetailsPostalAddress",
      validateCompletion(this.state, excludeList),
      this.state,
      this.values
    );
  }

  render() {
    const { emailRequired, onChange, defaults, errorInfo } = this.props;
    const { defaultCountry, defaultState } = this.state;
    const country =
      CountryMap[
        findIndex(CountryMap, (c) => {
          return c.abbr === defaultCountry;
        })
      ];
    const state = country.states;
    const requireZipCode = country.requireZipCode;
    let defaultErrors = buildFormErrorState(this.formInputs, errorInfo);

    CountryMap.sort((a, b) => {
      if (a.country === "United States" || b.country === "United States") {
        return -1;
      }
      if (a.country < b.country) {
        return -1;
      }
      if (a.country > b.country) {
        return 1;
      }
      return 0;
    });

    const onChangeHandler = (e, addtionalState) => {
      let currentState = cloneDeep(this.state);
      currentState[e.id] = !e.hasErrors;

      this.setState(merge(this.state, currentState, addtionalState));

      let excludeList = ["defaultCountry", "defaultState", "address2"];
      let states =
        CountryMap[
          findIndex(CountryMap, (c) => {
            return c.abbr === currentState.defaultCountry;
          })
        ].states;
      if (!states) {
        excludeList.push("state");
        excludeList.push("zipCode");
        delete this.values["state"];
      }

      this.values[e.id] = e.value;
      if (e.id === "country" && states) {
        this.values["state"] = "none";
      }

      if (!this.props.emailRequired) {
        excludeList.push("email");
        console.log("excludeList3", excludeList);
      }

      this.status = formatStatus(
        this.status.id,
        validateCompletion(currentState, excludeList),
        currentState,
        this.values
      );

      if (onChange) {
        onChange(this.status);
      }
    };

    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <ApricotFormGroupInput
              id="address1"
              label="Address 1"
              maxLength="30"
              isRequired={true}
              errorInfo={errorInfo}
              errorMessage={defaultErrors.address1}
              defaultValue={defaults.address1}
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-md-12">
            <ApricotFormGroupInput
              id="address2"
              label="Address 2"
              maxLength="30"
              errorInfo={errorInfo}
              errorMessage={defaultErrors.address2}
              defaultValue={defaults.address2}
              onChange={onChangeHandler}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ApricotFormGroupInput
              id="city"
              label="City"
              maxLength="35"
              errorInfo={errorInfo}
              errorMessage={defaultErrors.city}
              isRequired={true}
              defaultValue={defaults.city}
              onChange={onChangeHandler}
            />
          </div>
          {state !== undefined && (
            <div className="col-md-12">
              <ApricotFormGroupSelect
                id="state"
                label="State"
                isRequired={true}
                errorInfo={errorInfo}
                errorMessage={defaultErrors.state}
                defaultValue={defaultState}
                onChange={onChangeHandler}
              >
                <option
                  disabled={true}
                  id={"state.none"}
                  value={"none"}
                  key={"state.none"}
                >
                  Select State
                </option>
                {state.map((s) => {
                  return (
                    <option id={s.abbr} value={s.abbr} key={s.abbr}>
                      {s.state}
                    </option>
                  );
                })}
              </ApricotFormGroupSelect>
            </div>
          )}
          <div className="col-md-12">
            <ApricotFormGroupSelect
              id="country"
              label="Country"
              isRequired={true}
              errorInfo={errorInfo}
              errorMessage={defaultErrors.country}
              defaultValue={defaultCountry}
              onChange={(e) => {
                //let states = countries[findIndex(countries, (c) => { return c.abbr === this.state.defaultCountry; })].states;
                onChangeHandler(e, {
                  defaultState: "none",
                  defaultCountry: e.value,
                });
              }}
            >
              <option
                id={"country.none"}
                value={"none"}
                abbr={"country.none"}
                disabled={true}
              >
                Select Country
              </option>
              {CountryMap.sort((a, b) => {
                if (
                  a.country === "United States" ||
                  b.country === "United States"
                ) {
                  return -1;
                }
                if (a.country < b.country) {
                  return -1;
                }
                if (a.country > b.country) {
                  return 1;
                }
                return 0;
              }).map((c) => {
                return (
                  <option id={c.abbr} value={c.abbr} key={c.abbr}>
                    {c.country.toUpperCase()}
                  </option>
                );
              })}
            </ApricotFormGroupSelect>
          </div>
          <div className="col-md-6">
            <ApricotFormGroupInput
              id="zipCode"
              label="ZIP/Postal Code"
              errorInfo={errorInfo}
              errorMessage={defaultErrors.zipCode}
              maxLength="15"
              isRequired={requireZipCode}
              defaultValue={defaults.zipCode}
              onChange={onChangeHandler}
            />
          </div>
          {emailRequired === true && (
            <div className="col-md-12">
              <ApricotFormGroupInput
                id="email"
                label="Email Address"
                maxLength="50"
                onChange={onChangeHandler}
                isRequired={true}
                errorMessage={defaultErrors.email}
                validators={["email"]}
                defaultValue={defaults.email ? defaults.email : ""}
              ></ApricotFormGroupInput>
            </div>
          )}
        </div>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    ApricotUpdate();

    if (!isEqual(this.props.errorInfo, prevProps.errorInfo)) {
      let updatedState = buildFormState(
        this.formInputs,
        this.values,
        this.props.errorInfo
      );

      this.setState(updatedState);

      this.values.country = this.state.defaultCountry;

      let excludeList = ["defaultCountry", "defaultState", "address2"];
      let states =
        CountryMap[
          findIndex(CountryMap, (c) => {
            return c.abbr === this.state.defaultCountry;
          })
        ].states;
      if (!states) {
        excludeList.push("state");
        excludeList.push("zipCode");
        delete this.values["state"];
      }

      if (!this.props.emailRequired) {
        excludeList.push("email");
        console.log("excludeList0", excludeList);
      }

      console.log("excludeListJJJ", excludeList);
      this.status = formatStatus(
        this.status.id,
        validateCompletion(updatedState, excludeList),
        updatedState,
        this.values
      );

      const { onLoad } = this.props;
      if (onLoad) {
        onLoad(this.status);
      }
    }
  }

  componentDidMount() {
    const { onLoad } = this.props;
    if (onLoad) {
      onLoad(this.status);
    }
  }
}

class CreditCardBillingAddress extends Component {
  constructor(props) {
    super(props);

    this.values = {};
    this.state = {
      isLoading: {
        UserDetailsName: true,
        UserDetailsPostalAddress: true,
      },
      UserDetailsName: false,
      UserDetailsPostalAddress: false,
    };
  }

  render() {
    const { emailRequired, defaults, onChange, onLoad, errorInfo } = this.props;
    const onDataChangeHandler = (e, isLoader = false) => {
      this.values = merge(this.values, e.values);

      let currentState = cloneDeep(this.state);
      currentState[e.id] = e.isComplete;
      currentState.isLoading[e.id] = false;

      this.setState(merge(this.state, currentState));

      if (isLoader) {
        if (!isLoading(this.state.isLoading)) {
          onLoad(
            formatStatus(
              "CreditCardBillingAddress",
              validateCompletion(this.state),
              this.state,
              this.values
            )
          );
        }
      } else {
        onChange(
          formatStatus(
            "CreditCardBillingAddress",
            validateCompletion(this.state),
            this.state,
            this.values
          )
        );
      }
    };

    return (
      <div className="row">
        <div className="col-xs-12">
          <h2 className="cb-paragraph2-res cb-font-weight-light cb-margin-bottom-24">
            Credit Card Billing Address
          </h2>
          <UserDetailsName
            errorInfo={errorInfo}
            defaults={defaults}
            onLoad={(e) => {
              onDataChangeHandler(e, true);
            }}
            onChange={onDataChangeHandler}
          />
          <UserDetailsPostalAddress
            errorInfo={errorInfo}
            defaults={defaults}
            onLoad={(e) => {
              onDataChangeHandler(e, true);
            }}
            onChange={onDataChangeHandler}
            emailRequired={emailRequired}
          />
        </div>
      </div>
    );
  }
}

function PaymentAmount(props) {
  const { amount } = props;
  return (
    <div className="row">
      <div className="col-md-12">
        <h2 className="cb-paragraph2-res cb-font-weight-bold cb-margin-bottom-24 cb-border-bottom cb-width-100">
          Payment Amount
        </h2>
        <div className="row">
          <div className="col-md-12">
            <div className={$(styles["payment-amount"])}>
              <div className={$(styles["total"])}>
                <p>{amount}</p>
              </div>
              <p className={$(styles["policy"])}>
                The College Board is a not-for-profit membership association
                whose mission is to connect students to college success and
                opportunity. Proceeds are put back into programs and services
                that further the College Board's mission.{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.collegeboard.org/privacy-policy/privacy-statement"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class SecurityCodeIcon extends Component {
  render() {
    return (
      <>
        <div
          className="cb-tooltip hidden ecom-tooltip"
          id="securityCodeIcon_tip"
          role="tooltip"
          aria-hidden="true"
        >
          <div className="cb-tooltip-inner">
            <div className="cb-tip-header">
              <h3 className="cb-tip-title">Security Code</h3>
            </div>
            <div className="cb-tip-content">
              <div className={$("template-security-code")}>
                <div className={$(styles["cvc-container"])}>
                  <div className={$(styles["cc-back"])}>
                    <p className={$(styles["title"])}>
                      Visa, MasterCard &amp; Discover
                    </p>
                    <div className={$(images["cc-back"])}>
                      <span className="sr-only">
                        Located on the right of the back of the card, below the
                        magnetic strip
                      </span>
                    </div>
                    <p className={$(styles["note"])}>
                      3 Digit Card Verification
                    </p>
                  </div>
                  <div className={$(styles["cc-front"])}>
                    <p className={$(styles["title"])}>American Express</p>
                    <div className={$(images["cc-front"])}>
                      <span className="sr-only">
                        Located on the right of the back of the card, below the
                        magnetic strip
                      </span>
                    </div>
                    <p className={$(styles["note"])}>
                      4 Digit Card Verification
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <i
          tabIndex="0"
          role="button"
          aria-haspopup="dialog"
          aria-controls="securityCodeIcon_tip"
          aria-label="More information, for security code"
          className="ecom-tip-trigger cb-icon cb-exclamation-fill cb-blue5-color"
          id="securityCodeIcon_trigger"
        ></i>
      </>
    );
  }

  componentDidMount() {
    buildPopover("securityCodeIcon_trigger");
  }
}

class PaymentInformation extends Component {
  constructor(props) {
    super(props);

    this.formInputs = [
      "cards",
      "creditCardNumber",
      "expireMonth",
      "expireYear",
      "securityCode",
    ];
    this.excludeList = ["creditCardType", "typeOfCard"];
    if (!this.props.ccvRequired) {
      this.excludeList.push("securityCode");
    }

    this.values = copyObjectByList(this.props.defaults, this.formInputs);

    this.state = merge(
      {
        creditCardType: this.values.cards,
        typeOfCard: {
          hasError: false,
          errorMessage: false,
        },
      },
      buildFormState(this.formInputs, this.values)
    );
    this.defaultErrors = buildFormErrorState(
      this.formInputs,
      this.props.errorInfo
    );
    this.status = formatStatus(
      "PaymentInformation",
      validateCompletion(this.state, this.excludeList),
      this.state,
      this.values
    );

    this.typeOfCardRef = React.createRef();
    this.creditCardRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.errorInfo, prevProps.errorInfo)) {
      let updatedState = buildFormState(
        this.formInputs,
        this.values,
        this.props.errorInfo
      );

      this.setState(updatedState);

      this.status = formatStatus(
        this.status.id,
        validateCompletion(updatedState),
        updatedState,
        this.values
      );

      const { onLoad } = this.props;
      if (onLoad) {
        onLoad(this.status);
      }
    }
  }

  componentDidMount() {
    const { onLoad } = this.props;
    if (onLoad) {
      onLoad(this.status);
    }
  }

  render() {
    const { ccvRequired, defaults, errorInfo, onChange } = this.props;

    let defaultErrors = buildFormErrorState(this.formInputs, errorInfo);

    const onChangeHandler = (e, newState) => {
      let currentState = cloneDeep(this.state);
      currentState[e.id] = !e.hasErrors;
      this.values[e.id] = e.value;

      if (newState) {
        if (newState.typeOfCard) {
          currentState["cards"] = !newState.typeOfCard.hasError;
        }
      }

      let updatedState = merge(currentState, newState);
      this.setState(updatedState);

      this.status = formatStatus(
        "PaymentInformation",
        validateCompletion(updatedState, this.excludeList),
        updatedState,
        this.values
      );

      onChange(this.status);
    };

    return (
      <>
        <h2 className="cb-paragraph2-res cb-font-weight-light cb-margin-bottom-24 cb-border-bottom cb-width-100">
          Payment Information
        </h2>

        <div className="row">
          <div className="col-md-12">
            <ApricotFormGroupSelect
              id="cards"
              label="Type of Card"
              isRequired={true}
              hasError={this.state.typeOfCard.hasError}
              errorInfo={errorInfo}
              errorMessage={
                this.state.typeOfCard.errorMessage || defaultErrors.cards
              }
              defaultValue={this.values.cards}
              onChange={(e) => {
                this.values["cards"] = e.value;
                if (this.creditCardRef.current.state.isDirty) {
                  this.creditCardRef.current.updateState(
                    {
                      isDirty: true,
                    },
                    true,
                    {
                      creditCardType: e.value,
                    }
                  );
                } else {
                  onChangeHandler(e, {
                    creditCardType: e.value,
                    typeOfCard: {
                      hasError: false,
                      errorMessage: false,
                    },
                  });
                }
              }}
            >
              <option
                id={"creditcard.none"}
                key={"creditcard.none"}
                value="none"
                disabled
              >
                Select Card
              </option>
              {CreditCards.map((c) => {
                return (
                  <option key={c.value} value={c.value}>
                    {c.label}
                  </option>
                );
              })}
            </ApricotFormGroupSelect>
            <ApricotFormGroupInput
              ref={this.creditCardRef}
              id="creditCardNumber"
              defaultValue={defaults.creditCardNumber}
              errorInfo={errorInfo}
              errorMessage={defaultErrors.creditCardNumber}
              label="Credit Card Number"
              maxLength="30"
              autoComplete="off"
              isSecret={true}
              isRequired={true}
              validators={["creditcard"]}
              additionalData={{
                creditCardType: this.values.cards,
              }}
              onChange={(e) => {
                let updatedState = {
                  creditCardType: this.values.cards,
                  typeOfCard: {
                    hasError: false,
                    errorMessage: false,
                  },
                };
                if (this.values.cards === "none") {
                  updatedState = {
                    typeOfCard: {
                      hasError: true,
                      errorMessage: "Error: Select a Credit Card",
                    },
                  };
                }

                onChangeHandler(e, updatedState);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ApricotFormGroupSelect
              id="expireMonth"
              label="Expire Month"
              errorInfo={errorInfo}
              errorMessage={defaultErrors.expireMonth}
              defaultValue={defaults.expireMonth}
              isRequired={true}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            >
              <option
                id={"expireMonth.none"}
                name={"expireMonth.none"}
                value={"none"}
                disabled
              >
                Select a Month
              </option>
              {Months.map((m) => {
                return (
                  <option key={m.label} value={m.value}>
                    {m.label}
                  </option>
                );
              })}
            </ApricotFormGroupSelect>
          </div>
          <div className="col-md-6">
            <ApricotFormGroupSelect
              id="expireYear"
              label="Expire Year"
              errorInfo={errorInfo}
              errorMessage={defaultErrors.expireYear}
              defaultValue={defaults.expireYear}
              isRequired={true}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            >
              <option
                id={"expireYear.none"}
                name={"expireYear.none"}
                value={"none"}
                disabled
              >
                Select a Year
              </option>
              {getYears(30).map((y) => {
                return (
                  <option key={y.label} value={y.value}>
                    {y.label}
                  </option>
                );
              })}
            </ApricotFormGroupSelect>
          </div>
        </div>
        {ccvRequired === true && (
          <div className="row">
            <div className="col-md-6">
              <ApricotFormGroupInput
                id="securityCode"
                label="Security Code"
                maxLength="30"
                errorInfo={errorInfo}
                errorMessage={defaultErrors.securityCode}
                defaultValue={defaults.securityCode}
                onChange={onChangeHandler}
                isSecret={true}
                isRequired={true}
                validators={["numeric"]}
              >
                <SecurityCodeIcon />
              </ApricotFormGroupInput>
            </div>
          </div>
        )}
      </>
    );
  }
}

class ReviewAndSubmitPayment extends Component {
  render() {
    const { onSubmit, onCancel, isEnabled } = this.props;

    return (
      <div className={$(styles["form-controls"], "row")}>
        <div className="col-md-12">
          <h2 className="cb-paragraph2-res cb-font-weight-light cb-margin-bottom-24 cb-border-bottom cb-width-100">
            Review and Submit Payment
          </h2>

          <div className="row cb-margin-top-32">
            <div className="col-md-12">
              <div className="cb-btn-row">
                <button
                  disabled={!isEnabled}
                  name="submit"
                  className="cb-btn cb-btn-primary"
                  onClick={(e) => {
                    if (isEnabled) {
                      if (onSubmit) {
                        e.preventDefault();
                        onSubmit();

                        currentSubmitionCount++;
                      }
                    }
                  }}
                >
                  Submit
                </button>{" "}
                <button
                  name="cancel"
                  onClick={(e) => {
                    e.preventDefault();
                    onCancel(e);
                  }}
                  className="cb-btn cb-btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class PPSBilling extends Component {
  constructor(props) {
    super(props);

    this.values = {};
    this.state = {
      isCreditCardBillingAddressComplete: false,
      isPaymentInformationComplete: false,
    };
  }

  render() {
    const {
      emailRequired,
      amount,
      ccvRequired,
      onCancel,
      onSubmit,
      errorInfo,
    } = this.props;
    const { isCreditCardBillingAddressComplete, isPaymentInformationComplete } =
      this.state;

    let defaults = this.props.defaults || {};

    const onDataChangeHandler = (e) => {
      this.values = merge(this.values, e.values);

      let currentState = cloneDeep(this.state);
      currentState["is" + e.id + "Complete"] = e.isComplete;

      this.setState(merge(this.state, currentState));
    };

    return (
      <div className={$(styles.page, "pps-billing")}>
        <div className="container" role="region" aria-label="main">
          <div className="pps-container">
            <div id="#errors" tabIndex="-1">
              {errorInfo && (
                <div className="row">
                  <div className="col-md-6">
                    <ApricotErrorView
                      linkForm={true}
                      errorTitle={errorInfo.errorTitle}
                      errorInfo={errorInfo}
                      errorMessage={errorInfo.errorMessage}
                      errors={errorInfo.errors}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <h1 className="cb-paragraph3-res cb-font-weight-regular">
                  Make a Payment
                </h1>
                <div className="cb-border-top cb-padding-bottom-24" />
              </div>
            </div>
            <form className="row">
              <div className="col-md-6">
                <CreditCardBillingAddress
                  errorInfo={errorInfo}
                  defaults={defaults}
                  onChange={onDataChangeHandler}
                  onLoad={onDataChangeHandler}
                  emailRequired={emailRequired}
                />
                {amount !== undefined && (
                  <>
                    <br />
                    <br />
                    <PaymentAmount errorInfo={errorInfo} amount={amount} />
                  </>
                )}
                <br />
                <br />
                <PaymentInformation
                  errorInfo={errorInfo}
                  defaults={defaults}
                  ccvRequired={ccvRequired}
                  onChange={onDataChangeHandler}
                  onLoad={onDataChangeHandler}
                />
                <br />
                <br />
                <ReviewAndSubmitPayment
                  errorInfo={errorInfo}
                  isEnabled={
                    isCreditCardBillingAddressComplete &&
                    isPaymentInformationComplete
                  }
                  onCancel={onCancel}
                  onSubmit={() => {
                    onSubmit(this.values);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (currentSubmitionCount !== submitValidationCount) {
      submitValidationCount++;

      let errorElm = document.getElementById("#errors");
      let bodyElm = document.body;

      let errorElmRect = errorElm.getBoundingClientRect();
      let bodyElmRect = bodyElm.getBoundingClientRect();

      let offset = errorElmRect.top - bodyElmRect.top;

      window.scroll({
        top: offset,
        left: 0,
        behavior: "smooth",
      });

      const scrollCallback = (e) => {
        if (e.currentTarget.pageYOffset <= offset) {
          errorElm.focus();
          window.removeEventListener("scroll", scrollCallback);
        }
      };
      window.addEventListener("scroll", scrollCallback);
    }
  }

  componentDidMount() {
    ApricotUpdate();

    setPageTitle("Credit Card Payment Details | Make a Payment");
    track("payment-info", this.props.appId, this.props.flowCode);
  }
}

export default PPSBilling;
